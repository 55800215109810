var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"chinese-input",on:{"click":()=>{
      this.chineseshow=true;
      this.pinyin_switch=false;
      this.pinyin_array = [];
      this.pinyin_index = 0;
      this.pinyin_input = '';
  }}},[(!_vm.chineseInput)?_c('span',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.placeholder))]):_c('span',[_vm._v(_vm._s(_vm.chineseInput))])]),(_vm.chineseshow)?_c('div',[_c('div',{staticClass:"chinese-cover",on:{"click":()=>{this.chineseshow=false}}}),_c('div',{staticClass:"chinese-pinyin flex flex-x"},[_c('div',{staticClass:"chinese-cur flex-1"},[_c('div',{staticClass:"chinese-head flex"},[_c('div',[_vm._v(_vm._s(_vm.pinyin_input))]),_c('div',{staticClass:"chinese-hz flex flex-1"},_vm._l((_vm.pinyin_array[_vm.pinyin_index]),function(items,index){return _c('div',{key:index,staticClass:"flex-1",on:{"click":()=>_vm.inputFun(items)}},[_vm._v(" "+_vm._s(index+1)+"."+_vm._s(items)+" ")])}),0)]),_c('div',{staticClass:"chinese-head flex"},[_c('div',{staticClass:"flex-1"},[_vm._v("请输入完整的单字全拼")]),(_vm.pinyin_array.length>1)?_c('div',{staticClass:"chinese-left flex"},[_c('div',{staticClass:"arrow flex-1",class:{hui:_vm.pinyin_index==0},on:{"click":()=>_vm.arrowFun(-1)}},[_c('van-icon',{attrs:{"name":"arrow-left"}})],1),_c('div',{staticClass:"arrow flex-1",class:{hui:_vm.pinyin_index==_vm.pinyin_array.length-1},on:{"click":()=>_vm.arrowFun(1)}},[_c('van-icon',{attrs:{"name":"arrow"}})],1)]):_vm._e()]),_c('div',{staticClass:"cur-key"},_vm._l((_vm.pinyin),function(items,index){return _c('div',{key:index,staticClass:"flex"},_vm._l((items),function(item,index1){return _c('div',{key:index1,staticClass:"cur-hot flex flex-1"},[_c('div',{staticClass:"cur-color flex-1",class:{active:(item=='中' && !_vm.pinyin_switch)||(item=='英' && _vm.pinyin_switch)},on:{"click":()=>_vm.xuanz(item)}},[_vm._v(" "+_vm._s(item)+" ")])])}),0)}),0)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }