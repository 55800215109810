<template>
  <div>
    <div class="chinese-input" @click="()=>{
        this.chineseshow=true;
        this.pinyin_switch=false;
        this.pinyin_array = [];
        this.pinyin_index = 0;
        this.pinyin_input = '';
    }">
        <span v-if="!chineseInput" class="placeholder">{{placeholder}}</span>
        <span v-else>{{chineseInput}}</span>
    </div>
    <div v-if="chineseshow">
        <div class="chinese-cover" @click="()=>{this.chineseshow=false}"></div>
        <div class="chinese-pinyin flex flex-x">
            <div class="chinese-cur flex-1">
                <div class="chinese-head flex">
                    <div>{{pinyin_input}}</div>
                    <div class="chinese-hz flex flex-1">
                        <div v-for="(items, index) in pinyin_array[pinyin_index]" :key="index" class="flex-1" @click="()=>inputFun(items)">
                            {{index+1}}.{{items}}
                        </div>
                    </div>
                </div>
                <div class="chinese-head flex">
                    <div class="flex-1">请输入完整的单字全拼</div>
                    <div class="chinese-left flex" v-if="pinyin_array.length>1">
                        <div class="arrow flex-1" :class="{hui:pinyin_index==0}" @click="()=>arrowFun(-1)">
                            <van-icon name="arrow-left" />
                        </div>
                        <div class="arrow flex-1" :class="{hui:pinyin_index==pinyin_array.length-1}" @click="()=>arrowFun(1)">
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
                <div class="cur-key">
                    <div v-for="(items, index) in pinyin" :key="index" class="flex">
                        <div v-for="(item, index1) in items" :key="index1" class="cur-hot flex flex-1">
                            <div class="cur-color flex-1" :class="{active:(item=='中' && !pinyin_switch)||(item=='英' && pinyin_switch)}" @click="()=>xuanz(item)">
                                {{item}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
  import "./chinese.scss";
  export default {
    name: "chinese",
    data(){
      return{
          scriptsLoaded: 0,
          totalScripts: 2,
          chineseInput:'',
          chineseshow:false,
          pinyin:[
              ['a','b','c','d','e','f'],
              ['g','h','i','j','k','l'],
              ['m','n','o','p','q','r'],
              ['s','t','u','v','w','x'],
              ['y','z','中','英','删除']
          ],
          pinyin_input:'',
          pinyin_index:0,
          pinyin_array:[],
          pinyin_switch:false
      }
    },
    props: ["placeholder","inputMot"],
    methods:{
        xuanz(value){
            if(value=='删除'){
                if(this.pinyin_input.length>0){
                    this.pinyin_input = this.pinyin_input.slice(0, -1);
                }else if(this.chineseInput.length>0){
                    this.chineseInput = this.chineseInput.slice(0, -1);
                    this.inputMot(this.chineseInput);
                }
            }else if(value=='中'){
                this.pinyin_switch = false;
            }else if(value=='英'){
                this.pinyin_switch = true;
                this.pinyin_input = '';
            }else{
                if(this.pinyin_switch){
                    this.chineseInput = this.chineseInput+value;
                    this.inputMot(this.chineseInput);
                }else{
                    this.pinyin_input = this.pinyin_input+value;
                }
            }
            let str = window.pinyinUtil.getHanzi(this.pinyin_input);
            let chars = str.split('');
            let result = chars.reduce((acc, char, index) => {
                if (index % 6 === 0) {
                    acc.push([char]);
                } else {
                    acc[acc.length - 1].push(char);
                }
                return acc;
            }, []);
            this.pinyin_index = 0;
            this.pinyin_array = result;
        },
        arrowFun(index){
            if((this.pinyin_index+index)>=0 && (this.pinyin_index+index)<this.pinyin_array.length){
                this.pinyin_index = this.pinyin_index+index;
            }
        },
        inputFun(value){
            this.chineseInput = this.chineseInput+value;
            this.inputMot(this.chineseInput);
            this.pinyin_array = [];
            this.pinyin_index = 0;
            this.pinyin_input = '';
        }
    }
  };
</script>
